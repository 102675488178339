<template>
  <div>
    <el-form ref="queryForm" :inline="true" :model="queryForm" size="small">
      <el-form-item label="类型" prop="type">
        <el-select v-model="queryForm.type" placeholder="请选择" clearable>
          <el-option label="早餐" :value="1"></el-option>
          <el-option label="午餐" :value="2"></el-option>
          <el-option label="晚餐" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker v-model="dateRange" :clearable="false" unlink-panels :picker-options="pickerOptions"
          @change="handleDateRangeChange" type="daterange" placeholder="选择日期" value-format="yyyy-MM-dd"
          style="width: 260px;"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" :header-cell-style="{background:'#E4E7ED',color: '#606266'}"
      border stripe size="small" style="width: 100%;" max-height="500px">
      <el-table-column label="序号" type="index" width="60px"></el-table-column>
      <el-table-column label="日期" prop="pre_order_date"></el-table-column>
      <el-table-column label="类型" v-slot="{ row }">{{ mealTimeDict[row.type].name }}</el-table-column>
      <el-table-column label="姓名" prop="member.realname"></el-table-column>
      <el-table-column label="菜名">
        <template v-slot="{ row }">
          <p class="flex justify-content-s-b" v-for="item in row.item" :key="item.id">
            <span>{{ item.meal.meal_name }}</span>
            <span>x{{ item.num}}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column label="处理人" prop="user.name"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link v-if="row.status < 2" type="primary" @click="handleEdit(row)" class="margin-r-10">编辑</el-link>
          <el-popconfirm v-if="row.status !== 2" title="确定设为已处理吗？" @confirm="handle(row.id)">
            <el-link slot="reference" type="primary">处理</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="queryForm.page_size" @current-change="getTableData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" width="800px" :close-on-click-modal="false"
      :before-close="cancel">
      <el-form ref="form" :model="form" :rules="rules" label-width="70px">
        <el-form-item label="日期" prop="pre_order_date">
          <el-date-picker v-model="form.pre_order_date" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
            class="el-input_inner--rewrite"></el-date-picker>
        </el-form-item>
        <el-form-item label="点餐人" prop="member_id">
          <el-select v-model="form.member_id" :loading="memberLoading" remote filterable clearable
            :remote-method="getMemberData" placeholder="输入姓名进行搜索" class="el-input_inner--rewrite">
            <el-option v-for="item in memberData" :key="item.id" :label="item.realname" :value="item.id">
              <div class="flex justify-content-s-b">
                <span>{{ item.realname }}</span><span>{{ item.birthday }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="form.type" placeholder="请选择" clearable @change="handleMealTimeTypeChange"
            class="el-input_inner--rewrite">
            <el-option label="早餐" :value="1"></el-option>
            <el-option label="午餐" :value="2"></el-option>
            <el-option label="晚餐" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea"></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <h3>菜品选择</h3>
        <el-table :data="form.meal" size="small" style="width: 100%">
          <el-table-column label="菜品" v-slot="{ row }" width="280px">
            <div class="flex">
              <span style="color: #ff0000;">*</span>
              <el-select v-model="row.id" filterable clearable size="small" class="el-input_inner--rewrite">
                <el-option v-for="item in mealData" :key="item.id" :label="item.meal_name" :value="item.id">
                  <div class="flex justify-content-s-b">
                    <span>{{ item.meal_name }}</span>
                    <span style="font-size: 12px;color: #8492a6;">{{ item.flavour }}</span>
                  </div>
                </el-option>
              </el-select>
            </div>
          </el-table-column>
          <el-table-column label="数量" v-slot="{ row }" width="150px">
            <div class="flex">
              <span style="color: #ff0000;">*</span>
              <el-input-number v-model="row.num" :min="0" size="small"></el-input-number>
            </div>
          </el-table-column>
          <el-table-column label="备注" v-slot="{ row }">
            <el-input v-model="row.remark" size="small"></el-input>
          </el-table-column>
          <el-table-column label="操作" v-slot="{ $index }" width="60px">
            <el-popconfirm title="确定删除吗？" @confirm="delRow($index)">
              <el-link slot="reference" type="danger">删除</el-link>
            </el-popconfirm>
          </el-table-column>
        </el-table>
        <div class="text-center margin-t-20">
          <el-button @click="addRow" type="primary" size="small">新增一行</el-button>
        </div>
      </el-form>
      <span slot="footer">
        <el-button @click="cancel">关闭</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
  import {
    getMealPreorderListAPI,
    saveMealPreorderListAPI,
    getMealListAPI,
    setPreorderAPI
  } from '@/api/order.js'
  import {
    getUserRecordDataAPI
  } from '@/api/user-record/record.js'

  const mealDetail = {
    id: '',
    num: 1,
    remark: ''
  }

  export default {
    name: 'OrderList',
    data() {
      return {
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        mealTimeDict: {
          1: {
            name: '早餐',
            value: 1
          },
          2: {
            name: '午餐',
            value: 2
          },
          3: {
            name: '晚餐',
            value: 3
          }
        },

        // 搜索相关
        queryForm: {
          page: 1,
          page_size: 10,
          type: '',
          start: '',
          end: ''
        },
        dateRange: [],

        // 表格相关
        total: 1,
        tableLoading: false,
        tableData: [],
        curRowId: '',

        // 点餐表单相关
        dialogTitle: '',
        showDialog: false,
        submitting: false,
        form: {
          pre_order_date: '',
          type: 1,
          member_id: '',
          meal: [],
          remark: ''
        },
        rules: {
          pre_order_date: [{
            required: true,
            message: '日期不能为空',
            trigger: 'change'
          }],
          member_id: [{
            required: true,
            message: '点餐人不能为空',
            trigger: 'change'
          }],
          type: [{
            required: true,
            message: '类型不能为空',
            trigger: 'change'
          }],
        },

        memberData: [],
        memberLoading: false,

        mealData: [],
      }
    },
    created() {
      this.setDefaultDateRange()
      this.getTableData()
    },
    methods: {
      handleSearch() {
        this.queryForm.page = 1
        this.getTableData()
      },
      handleDateRangeChange(e) {
        this.queryForm.start = e[0]
        this.queryForm.end = e[1]
      },
      setDefaultDateRange() {
        this.dateRange = this.getCurDate()
        this.queryForm.start = this.dateRange[0]
        this.queryForm.end = this.dateRange[1]
      },
      handleReset() {
        this.setDefaultDateRange()
        this.$refs.queryForm.resetFields()
      },
      async handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑点餐'
        this.curRowId = row.id
        const copyRow = JSON.parse(JSON.stringify(row))
        this.mealData = await this.getMealList(copyRow.type)
        // 回显
        // 回显点餐人
        this.memberData = [copyRow.member]
        // 回显表单
        for (let key in this.form) {
          this.form[key] = copyRow[key]
        }
        // 回显菜品详情
        this.form.meal = copyRow.item.map(item => {
          return {
            id: item.meal_id,
            num: item.num,
            remark: item.remark
          }
        })
      },
      async handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增点餐'
        this.mealData = await this.getMealList(1)

        this.$nextTick(() => this.addRow())
      },
      async handleMealTimeTypeChange(type) {
        this.form.meal = []
        this.mealData = await this.getMealList(type)
        this.addRow()
      },
      addRow() {
        const row = Object.assign({}, mealDetail)
        this.form.meal.push(row)
      },
      delRow(index) {
        this.form.meal.splice(index, 1)
      },
      cancel() {
        this.form.meal = []
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return
          if (!this.validateMealDetails()) return

          // if (this.form.meal.length) {
          //   this.form.meal = this.form.meal.filter(item => {
          //     // 只要有一个有值，就保留这条数据
          //     if (item.id || item.num || item.remark) {
          //       return true
          //     } else {
          //       return false
          //     }
          //   })
          // }

          this.submitting = true
          const copyForm = JSON.parse(JSON.stringify(this.form))

          if (this.dialogTitle == '编辑点餐') copyForm.id = this.curRowId

          saveMealPreorderListAPI(copyForm).then(res => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              this.cancel()
              this.getTableData()
            }
          }).finally(() => this.submitting = false)
        })
      },
      // 验证菜品详情，return bool
      validateMealDetails() {
        if (!this.form.meal.length) {
          this.$message.error('未选择菜品')
          return false
        }
        // 判断是否选择了菜品
        for (let item of this.form.meal) {
          if (!item.id) {
            this.$message.error('请选择菜品')
            return false
          }
        }
        return true
      },
      getMemberData(keyword = '') {
        this.memberLoading = true

        setTimeout(() => {
          getUserRecordDataAPI({
            page: 1,
            keyword,
          }).then(res => {
            this.memberData = res.data
            this.memberLoading = false
          })
        }, 300)
      },
      handle(id) {
        setPreorderAPI({
          id,
          field: 'status',
          value: 2
        }).then(() => {
          this.$message.success('处理成功');
          this.getTableData();
        })
      },
      getTableData() {
        this.tableLoading = true

        getMealPreorderListAPI(this.queryForm).then(res => {
          this.tableLoading = false
          this.total = res.total
          this.tableData = res.data
        })
      },
      async getMealList(type) {
        const result = await getMealListAPI({
          page: 1,
          status: 1,
          type,
          is_all: 1
        }).then(res => res)
        return result
      },
      // 获取当月第一天和当天
      getCurDate() {
        const date = new Date()
        const year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let firstDate = new Date(year, month, 1)
        let firstDay = firstDate.getDate()
        // 拼0
        month = month > 10 ? month : `0${month}`
        day = day > 10 ? day : `0${day}`
        return [`${year}-${month}-${firstDay}`, `${year}-${month}-${day}`]
      },
    }
  }
</script>


<style>
</style>